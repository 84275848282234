import { useMemo } from "react";
import { useFetchHeaderNavItems } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import { useNavigationBar } from "../useNavigationBar";
import { useNavItemsToLinks } from "./useNavItemsToLinks";

export interface NavigationBarProps {
  isDisabled?: boolean;
}

export const useNavigationLinks = ({
  isDisabled = false,
}: NavigationBarProps = {}) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();
  const { homeLinkTo, maxVisibleLinks } = useNavigationBar();

  const { data: headerNavItems, isLoading: isLoadingLinks } =
    useFetchHeaderNavItems({
      id: currentCommunity?.id,
    });

  const {
    isFeedRoute,
    isSpaceRoute,
    isSpaceGroupRoute,
    isGettingStartedRoute,
  } = useRouteHelpers();

  const isHomeLinkActive =
    isDisabled ||
    isSpaceRoute ||
    isSpaceGroupRoute ||
    isFeedRoute ||
    isGettingStartedRoute;

  const links = useNavItemsToLinks({
    headerNavItems: headerNavItems ?? [],
    isDisabled,
    homeLinkTo,
    isHomeLinkActive,
    isCommunityAdmin: isCommunityAdmin(currentCommunityMember),
  });

  const { visibleLinks, hiddenLinks } = useMemo(() => {
    if (links.length <= maxVisibleLinks + 1) {
      return {
        visibleLinks: links,
        hiddenLinks: [],
      };
    }
    const visibleLinks = links.slice(0, maxVisibleLinks);
    const hiddenLinks = links.slice(maxVisibleLinks);
    return { visibleLinks, hiddenLinks };
  }, [links, maxVisibleLinks]);

  const eventLink = links.find(link => link.kind === "events");
  const courseLink = links.find(link => link.kind === "courses");
  const leaderboardLink = links.find(link => link.kind === "leaderboard");
  const memberDirectoryLink = links.find(link => link.kind === "members");
  const enabledCustomLinks = links.filter(
    link => link.kind === "custom" && !link.isDisabled,
  );

  return {
    courseLink,
    enabledCustomLinks,
    eventLink,
    hiddenLinks,
    isLoadingLinks,
    leaderboardLink,
    links,
    maxVisibleLinks,
    memberDirectoryLink,
    visibleLinks,
  };
};
